<template>
  <div id="app" class="tw-min-h-screen tw-bg-fagforbundetbackground tw-flex tw-flex-col tw-justify-center">
    <header class="tw-bg-white tw-w-screen ">
      <div class="tw-container tw-flex tw-justify-between tw-mx-auto tw-max-w-6xl  ">
        <div class="tw-flex tw-items-center tw-py-10 ">
          <RouterLink to="/">
            <img src="./assets/images/ff-avtaledatabase-logo.png" alt="Fagforbundet-avtaledatabase" class="tw-flex tw-h-12 tw-px-4 ">
          </RouterLink>
        </div>
      </div>
    </header>
    <main class="tw-flex-grow tw-flex tw-justify-center tw-items-start">
      <div class="tw-container tw-mx-auto tw-px-4">
        <div class="tw-max-w-2xl tw-mx-auto">
          <router-view 
            v-if="!isLoading"
            :key="$route.fullPath"
            :agreements="displayedAgreements" 
            :error="error"
            @search-results="handleSearchResults">
          </router-view>
          <div v-else class="tw-flex tw-justify-center tw-items-center tw-my-8">
            <div class="tw-animate-spin tw-rounded-full tw-h-12 tw-w-12 tw-border-t-2 tw-border-b-2 tw-border-fagfordbundetRed"></div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      allAgreements: null,
      searchResults: null,
      isLoading: false,
      error: null
    }
  },
  computed: {
    displayedAgreements() {
      return this.searchResults !== null ? this.searchResults : this.allAgreements;
    }
  },
  methods: {
    async fetchAllAgreements() {
      this.isLoading = true;
      this.error = null;
      try {
        const baseUrl = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.get(`${baseUrl}/agreement`, {
          params: {
            include: 'current'
          }
        });
        
        if (!response.data) {
          throw new Error('Ingen data mottatt fra serveren');
        }
        
        this.allAgreements = response.data;
      } catch (error) {
        console.error('Feil ved henting av avtaler: ', error);
        this.error = 'Det oppstod en feil ved henting av avtaler. Vennligst prøv igjen senere.';
        this.allAgreements = null;
      } finally {
        this.isLoading = false;
      }
    },
    handleSearchResults(results) {
      this.searchResults = results;
    }
  },
  mounted() {
    this.fetchAllAgreements();
  }
}
</script>

<style>
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
}
</style>
