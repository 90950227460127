<template>
  <div class="tw-mb-8"> <!-- tw-px-16  -->
    <div class="tw-relative tw-mb-12"> 
      <input 
        v-model="searchQuery"
        @input="debounceSearch"
        type="text" 
        placeholder="Søk etter avtaletittel, avtalenummer eller tekst" 
        class="tw-w-full tw-px-4 tw-py-4 tw-border tw-border-gray-300 tw-rounded tw-text-lg tw-shadow-sm focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-fagforbundetBlack focus:tw-border-transparent"
        >
      <button @click="clearSearch" v-if="searchQuery" class="tw-absolute tw-right-12 tw-top-1/2 tw-transform -tw-translate-y-1/2">
        <svg class="tw-w-5 tw-h-5 tw-text-fagforbundetGreyligt" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
      <button @click="performSearch" class="tw-absolute tw-right-2 tw-top-1/2 tw-transform -tw-translate-y-1/2">
        <svg class="tw-w-5 tw-h-5 tw-text-fagforbundetGreyligt" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
        </svg>
      </button>
    </div>
      </div>
</template>

<script>
import axios from 'axios';
import { debounce } from 'lodash';

export default {
  name: 'AgreementSearch',
  data() {
    return {
      searchQuery: '',
      isLoading: false,
      searchResults: null,
      error: null,
    }
  },
  methods: {
    debounceSearch: debounce(function() {
      this.performSearch();
    }, 390),
    async performSearch() {
      this.$emit('search-input', this.searchQuery);
      
      if (!this.searchQuery) {
        this.searchResults = null;
        this.$emit('search-results', null, '');
        return;
      }
      this.isLoading = true;
      this.error = null;
      this.$parent.isLoading = true;
      try {
        const baseUrl = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.get(`${baseUrl}/agreement/SearchBy`, {
          params: {
            searchText: this.searchQuery,
            include: 'current'
          }
        });
        this.$emit('search-results', response.data, this.searchQuery);
      } catch (error) {
        console.error('Error fetching agreements:', error);
        this.$emit('search-results', null, this.searchQuery);
        this.error = 'Det oppstod en feil under søket. Vennligst prøv igjen senere.';
      } finally {
        this.isLoading = false;
      }
    },
    clearSearch() {
      this.searchQuery = '';
      this.error = null;
      this.$emit('search-input', '');
      this.$emit('search-results', null, '');
    }
  },
  watch: {
    searchQuery() {
      this.$emit('search-input', this.searchQuery);
      this.debounceSearch();
    }
  }
}
</script>