import axios from 'axios'

const api = axios.create()

api.interceptors.request.use(config => {
  if (config.url.startsWith('/api/v1')) {
    const controller = new AbortController()
    config.signal = controller.signal
    controller.abort()
    
    window.location.replace(`https://avtale-legacy.fagforbundet.no?ourl=${encodeURIComponent(config.url)}`)
    
    return Promise.reject('Redirecting to legacy system')
  }
  return config
}, error => {
  return Promise.reject(error)
})

export default api 