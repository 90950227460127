import { createRouter, createWebHistory } from 'vue-router'
import AgreementComponent from './components/AgreementComponent.vue'
import AgreementDetails from './components/AgreementDetails.vue'

const routes = [
  { path: '/', name: 'Home', component: AgreementComponent, props: true },
  { 
    path: '/avtaler/:id', 
    name: 'AgreementDetails', 
    component: AgreementDetails,
    props: true
  },
  { 
    path: '/avtaler/:id/versjoner/:versionId', 
    name: 'AgreementVersion', 
    component: AgreementDetails,
    props: route => ({
      id: route.params.id,
      initialVersionId: route.params.versionId
    })
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router