<template>
  <div class="tw-w-full tw-justify-center tw-text-fagforbundetTextColor">
    <div @click="resetSearch" class="tw-cursor-pointer">
      <h2 class="tw-text-5xl tw-font-bold tw-mb-4 tw-text-center tw-py-11 tw-text-fagforbundetTextColor">Finn avtaler</h2>
    </div>
    
    <AgreementSearch ref="agreementSearch" @search-results="handleSearchResults" @search-input="handleSearchInput" />
    
    <div v-if="isLoading" class="tw-flex tw-justify-center tw-items-center tw-my-8">
      <div class="tw-animate-spin tw-rounded-full tw-h-12 tw-w-12 tw-border-t-2 tw-border-b-2 tw-border-fagfordbundetRed"></div>
    </div>

    <template v-else>
      <div v-if="error || (!displayedAgreements && !isLoading && !searchQuery)" class="tw-text-center tw-text-fagfordbundetRed tw-py-4 tw-mt-4">
        <p>{{ error || 'Det oppstod en feil ved henting av avtaler. Vennligst prøv igjen senere.' }}</p>
      </div>

      <template v-else>
        <h3 v-if="!searchQuery" class="tw-text-2xl tw-font-bold tw-mb-4 tw-text-fagforbundetTextColor">
          Sist oppdaterte avtaler
        </h3>
        <h3 v-else-if="searchPerformed && displayedAgreements?.data?.length" class="tw-text-2xl tw-font-bold tw-mb-4 tw-text-gray-700">
          Søkeresultater
        </h3>
        
        <div v-if="displayedAgreements && displayedAgreements.data && displayedAgreements.data.length">
          <div v-for="agreement in filteredAgreements" :key="agreement.id" class="agreement-item tw-mb-4">
            <div class="tw-flex tw-items-center tw-py-2">
              <div class="tw-flex-shrink-0 tw-mr-4">
                <div class="tw-w-20 tw-h-22 tw-flex  tw-flex-col tw-items-center tw-justify-center tw-rounded tw-relative tw-hidden sm:tw-flex">
                  <img :src="getAgreementTypeIcon(agreement.attributes.type)" :alt="agreement.attributes.type + ' Icon'" class="tw-w-full tw-h-full tw-rounded-">
                  <p class="tw-absolute tw-text-center tw-justify-center tw-bg-white tw-bg-opacity-75 tw-px-2 tw-text-sm"
                     :style="{ color: getTextColor(agreement.attributes.type) }">
                    {{ formatAgreementNumber(agreement.attributes.number) }}
                  </p>
                </div>
              </div>
              <div>
                <h4 @click="goToAgreement(agreement.id)" class="tw-text-xl tw-font-bold tw-text-fagfordbundetRed tw-cursor-pointer hover:tw-underline">
                  {{ getExternalTitle(agreement) }}
                </h4>
                <p class="tw-text-m tw-text-fagforbundetTextColor">{{ getDescription(agreement) }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="searchQuery && searchPerformed">
          <p>Vi fant dessverre ingen avtaler som samsvarer med søket ditt...</p>
        </div>
      </template>
    </template>
    
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import AgreementSearch from './AgreementSearch.vue'

export default {
  name: 'AgreementComponent',
  components: {
    AgreementSearch
  },
  props: {
    agreements: {
      type: Object,
      default: null
    },
    error: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      htaIcon: require('@/assets/icons/agreement-hta.56ad2e71.png'),
      saIcon: require('@/assets/icons/agreement-sa.bdc20c94.png'),
      haIcon: require('@/assets/icons/agreement-ha.ea07f46a.png'),
      blankIcon: require('@/assets/icons/agreement-blank.b6ec5d9e.png'),
      searchResults: null,
      searchPerformed: false,
      searchQuery: '',
      isLoading: false,
      initialLoading: false,
      router: useRouter()
    };
  },
  methods: {
    goToAgreement(id) {
      this.router.push({ name: 'AgreementDetails', params: { id: id.toString() } });
    },
    getAgreementTypeIcon(type) {
      switch(type) {
        case '172200000': // HA
          return this.haIcon;
        case '172200001': // HTA
          return this.htaIcon;
        case '172200002': // SA
          return this.saIcon;
        case 'HA':
          return this.haIcon;
        case 'HTA':
          return this.htaIcon;
        case 'SA':
          return this.saIcon;
        case 'AA':
          return this.saIcon;
        case 'TA':
          return this.htaIcon;
        default:
          return this.blankIcon;
      }
    },
    getAgreementTypeName(type) {
      switch(type) {
        case '172200000':
          return 'HA';
        case '172200001':
          return 'HTA';
        case '172200002':
          return 'AA';
        default:
          return 'Unknown';
      }
    },
    getExternalTitle(agreement) {
      if (!this.displayedAgreements || !this.displayedAgreements.included ) return 'No title available';
      const currentVersionId = agreement.relationships.current.data.id;
      const currentVersion = this.displayedAgreements.included.find(item => item.id === currentVersionId);
      return currentVersion ? (currentVersion.attributes.titleExternal || currentVersion.attributes.title) : 'No title available';
    },
    getDescription(agreement) {
      if (!this.displayedAgreements || !this.displayedAgreements.included) return 'No description available';
      const currentVersionId = agreement.relationships.current.data.id;
      const currentVersion = this.displayedAgreements.included.find(item => item.id === currentVersionId);
      return currentVersion ? currentVersion.attributes.description : 'No description available';
    },
    handleSearchInput(query) {
      this.searchQuery = query;
    },
    handleSearchResults(results, query) {
      this.searchResults = results;
      this.searchPerformed = !!query;
      this.searchQuery = query;
      this.isLoading = false;
    },
    formatAgreementNumber(number) {
      if (typeof number === 'string') {
        return number
          .replace(/^SPEKTER\s*/i, 'Spekter ')
          .replace(/^STAT\s*/i, 'Stat ');
      }
      return number;
    },
    getTextColor(type) {
      return ['HA', 'AA'].includes(type) ? 'white' : 'tw-text-fagforbundetTextColor';
    },
    resetSearch() {
      this.searchResults = null;
      this.searchPerformed = false;
      this.searchQuery = '';
      if (this.$refs.agreementSearch) {
        this.$refs.agreementSearch.clearSearch();
      }
    },
  },
  mounted() {
    this.initialLoading = !this.agreements;
  },
  watch: {
    agreements: {
      immediate: true,
      handler(newVal) {
        console.log('Agreements changed:', newVal ? 'exists' : 'null');
        if (newVal) {
          this.initialLoading = false;
          this.isLoading = false;
        }
      }
    },
    error: {
      immediate: true,
      handler(newVal) {
        console.log('Error changed:', newVal);
        if (newVal) {
          this.initialLoading = false;
          this.isLoading = false;
        }
      }
    }
  },
  computed: {
    displayedAgreements() {
      console.log('Computing displayedAgreements:', {
        searchQuery: this.searchQuery,
        searchResults: this.searchResults,
        agreements: this.agreements
      });
      return this.searchQuery ? this.searchResults : this.agreements;
    },
    filteredAgreements() {
      if (!this.displayedAgreements || !this.displayedAgreements.data || !this.displayedAgreements.included) {
        return [];
      }
            
      const sortedAgreements = [...this.displayedAgreements.data].sort((a, b) => {
        const versionA = this.displayedAgreements.included.find(item => item.id === a.relationships.current.data.id);
        const versionB = this.displayedAgreements.included.find(item => item.id === b.relationships.current.data.id);
        
        const dateA = versionA ? new Date(versionA.attributes.modified) : new Date(0);
        const dateB = versionB ? new Date(versionB.attributes.modified) : new Date(0);
        
        if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
          console.warn('Invalid date encountered');
          return 0;
        }
        
        return dateB - dateA;
      });
      
      const uniqueAgreements = sortedAgreements.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      
      if (this.searchQuery) {
        return uniqueAgreements;
      }
      
      return uniqueAgreements.slice(0, 10);
    }
  }
}
</script>
<style>
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}
</style>